.contact-page-wrap {
  overflow-x: hidden;
  background-color: black;
  // position: relative;
}

.contact-wrap {
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
}

.contact {
  color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;

  .success-message {
    margin-bottom: 0;
    text-align: center;
  }
  .skin {
    color: #d7a48f !important;
  }

  .logo-img {
    padding: 40px 0 50px;
    width: 87px;

    img {
      max-width: 100%;
      width: 100%;
    }
  }

  .navbar {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 50px;

    .text {
      font-size: 26px;
      cursor: pointer;
      text-decoration: none;
      color: #fff;
      font-family: "CurvedSquare";
    }
  }

  .content {
    overflow: hidden;
    position: relative;
    margin-top: 60px;
    background: #0c0c0c;
    border-radius: 15px;

    max-width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 60px 20px;

    .data {
      width: 45%;
      padding: 20px 30px;

      .title {
        font-size: 32.65px;
        margin-bottom: 10px;
        text-align: center;
        font-family: "CurvedSquare";
      }

      .para {
        display: flex;
        flex-direction: column;
        gap: 10px;

        font-size: 12.19px;
      }

      .C-font {
        font-size: 9.83px;
      }

      .social {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 15px;

        img {
          width: 20px;
          cursor: pointer;
        }
      }
    }

    .image {
      position: relative;
      width: 55%;

      img {
        max-width: 100%;
        width: 100%;
      }

      .shadow {
        position: absolute;
        top: 0;
        // left: -70px;
        left: -30px;
        z-index: 1;

        // box-shadow: 105px 1px 90px 45px rgba(0, 0, 0, 0.75);
        // box-shadow: 175px 1px 78px 18px rgba(0, 0, 0, 0.75);
        // box-shadow: 30px 0px 57px 18px rgba(0, 0, 0, 0.75);
        box-shadow: 55px 0px 67px 18px rgba(0, 0, 0, 0.75);
        background: transparent;
        height: 100%;
        width: 0px;
      }
    }

    .arrow {
      position: absolute;
      z-index: 2;
      bottom: 30px;
      cursor: pointer;
    }
  }
}
.btn-artist {
  text-align: center;
  background-color: white;
  border: 1px solid black;
  width: 106px;
  color: black;
  font-family: "Circular-Loom";
  justify-content: center;
  padding: 4px;
  border-radius: 7px;
}
.btn-adjust {
  text-align: center;
  margin-top: 28px;
}
@media screen and (max-width: 700px) {
  .contact {
    .content {
      flex-direction: column;
      padding: 40px;

      .data {
        width: 100%;
        max-width: 400px;
        padding: 0 0px;
      }

      .image {
        margin-top: 30px;
        position: relative;
        width: 100%;

        img {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .contact {
    .logo-img {
      padding: 30px 0;
      width: 80px;
    }

    .navbar {
      gap: 50px;
      padding: 0 20px;
      .text {
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .contact {
    .navbar {
      gap: 30px;
      padding: 0;

      .text {
        font-size: 18px;
      }
    }

    .content {
      padding: 40px 20px;
      margin: 50px 10px;
    }
  }
}
