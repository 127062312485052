.about-wrap {
  overflow-x: hidden;
  background-color: black;
  // position: relative;
}

.about {
  // background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  // transform: translateX(50%);

  // transform: translate(-50%, 0%);

  color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;

  .logo-img {
    padding: 40px 0 50px;
    width: 87px;

    img {
      max-width: 100%;
      width: 100%;
    }
  }

  .navbar {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 50px;

    .text {
      font-size: 26px;
      cursor: pointer;
      text-decoration: none;
      color: #fff;
      font-family: "CurvedSquare";
    }
  }

  .content {
    position: relative;
    margin-top: 60px;
    background: #0c0c0c;
    border-radius: 15px;

    max-width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 60px 20px;
    padding: 120px 40px;

    .data {
      width: 50%;

      .title {
        font-size: 30.12px;
        margin-bottom: 10px;
        font-family: "CurvedSquare";
      }

      .para {
        display: flex;
        flex-direction: column;
        gap: 10px;

        font-size: 13.26px;
      }
    }

    .image {
      width: 50%;

      img {
        max-width: 100%;
        width: 100%;
      }
    }

    .arrow {
      position: absolute;
      bottom: 30px;
      cursor: pointer;
    }
  }

  .about-content1 {
    justify-content: center;

    .data {
      min-width: 80%;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 40px;
      border-radius: 20px;

      .para {
        .b-font {
          font-weight: bold;
        }

        .skin {
          color: lightblue;
        }
      }
    }
  }
  .founder-img {
    width: 100%;
  }
  .our-team {
    display: flex;
    flex-direction: column;
    padding: 60px 0 90px;

    .main-title {
      font-size: 32.65px;
      font-weight: bold;
    }

    .wrapper {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 40px;
    }

    .text-wrap {
      display: flex;
      flex-direction: column;
      width: 190px;
      font-size: 13px;
      font-weight: bold;

      .title {
        color: #1f518f;
      }

      .sub {
        margin-top: 5px;
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .about {
    .content {
      margin: 60px 20px;
      padding: 80px 40px 100px;

      .data {
        width: 60%;
        padding-right: 10px;
      }

      .image {
        width: 40%;
      }
    }

    .about-content1 {
      .data {
        min-width: 0;
        width: 100%;
        padding: 30px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .about {
    .logo-img {
      padding: 30px 0;
      width: 80px;
    }

    .navbar {
      gap: 50px;
      padding: 0 20px;
      .text {
        font-size: 20px;
      }
    }

    .content {
      margin: 50px 20px;
      padding: 60px 40px 80px;
      flex-direction: column;

      .data {
        width: 100%;
        padding-right: 0px;
      }

      .image {
        margin-top: 40px;
        width: 90%;
      }
    }

    .about-content1 {
      padding: 50px 30px 50px;

      .data {
        padding: 30px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .about {
    .navbar {
      gap: 30px;
      padding: 0;

      .text {
        font-size: 18px;
      }
    }

    .content {
      margin: 50px 10px;
      padding: 60px 20px 80px;

      .data {
        padding-right: 10px;

        .title {
          font-size: 28px;
        }

        .para {
          font-size: 12px;
        }
      }
    }

    .about-content1 {
      padding: 40px 10px 40px;

      .data {
        padding: 20px;
      }
    }
  }
}
