.artist-wrap {
  overflow-x: hidden;
  background-color: black;
  // position: relative;
}

.artist-main-content-wrap {
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
}

.artist {
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo-img {
    padding: 40px 0 50px;
    width: 87px;
    margin: auto;
    img {
      max-width: 100%;
      width: 100%;
    }
  }

  .navbar {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 50px;

    .text {
      font-size: 26px;
      cursor: pointer;
      text-decoration: none;
      color: #fff;
      font-family: "CurvedSquare";
    }
  }
  .background-color-content {
    // background-color: #0c0c0c;
    border-radius: 20px;
  }
  .content {
    overflow: hidden;
    position: relative;
    margin-top: 60px;
    background: #0c0c0c;
    border-radius: 15px;
    flex-direction: column;

    max-width: 575px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 60px 20px;

    .data {
      width: 63%;
      padding: 20px 30px;

      .title {
        font-size: 32.65px;
        margin-bottom: 10px;
        text-align: center;
        font-family: "CurvedSquare";

        .skin {
          // color: #d7a48f;
          color: #fff;
        }
      }

      .para {
        display: flex;
        flex-direction: column;
        gap: 10px;
        // font-weight: bold;
        font-size: 12.19px;

        .skin {
          // color: #d7a48f;
          color: #fff;
        }

        .C-font {
          font-size: 9.83px;
        }
      }

      .social {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 15px;

        img {
          width: 20px;
          cursor: pointer;
        }
      }
    }

    .image {
      position: relative;
      width: 55%;

      img {
        max-width: 100%;
        width: 100%;
      }

      .shadow {
        position: absolute;
        top: 0;
        // left: -70px;
        left: -30px;
        z-index: 1;
        box-shadow: 2px 17px 133px 0px rgba(49, 42, 42, 0.75);
        -webkit-box-shadow: 2px 17px 133px 0px rgba(49, 42, 42, 0.75);
        -moz-box-shadow: 2px 17px 133px 0px rgba(49, 42, 42, 0.75);
        // box-shadow: 105px 1px 90px 45px rgba(0, 0, 0, 0.75);
        // box-shadow: 175px 1px 78px 18px rgba(0, 0, 0, 0.75);
        // box-shadow: 30px 0px 57px 18px rgba(0, 0, 0, 0.75);
        box-shadow: 55px 0px 67px 18px rgba(0, 0, 0, 0.75);
        background: transparent;
        height: 100%;
        width: 0px;
      }
    }

    .arrow {
      position: absolute;
      z-index: 2;
      bottom: 30px;
      cursor: pointer;
    }
  }
  .carousel-width {
    max-width: 55%;
  }
  .content1 {
    .data {
      .title {
        .skin {
          // color: purple;
          color: #fff;
        }
      }

      .para {
        text-align: start;

        .skin {
          // color: #a10ba1;
          color: #fff;
        }
      }
    }

    .image {
      .shadow {
        left: auto;
        right: 90px;
      }
    }
  }

  .content2 {
    .data {
      .title {
        .skin {
          // color: red;
          color: #fff;
          font-family: "CurvedSquare";
        }
      }

      .para {
        text-align: start;

        .skin {
          // color: yellow;
          color: #fff;
        }

        .b-font {
          font-weight: bold;
          font-size: 14px;
        }
      }
    }

    .image {
      .shadow {
        left: auto;
        right: 90px;
      }
    }
  }

  .content4 {
    .data {
      .title {
        .skin {
          // color: #635e0d;
          color: #fff;
          font-family: "CurvedSquare";
        }
      }

      .para {
        .skin {
          // color: #8f881f;
          color: #fff;
        }
      }
    }
  }
}
.content1 {
  flex-direction: column-reverse;
}

@media screen and (max-width: 750px) {
  .artist {
    .content {
      flex-direction: column;
      padding: 40px;

      .data {
        width: 100%;
        max-width: 400px;
        padding: 0 0px;
      }

      .image {
        margin-top: 30px;
        position: relative;
        max-width: 445px;
        width: 100%;

        img {
          max-width: 100%;
          width: 100%;
        }
      }
    }
    // .content1,
    // .content4,
    // .content5,
    // .content6 {
    //   flex-direction: column-reverse;
    // }

    .our-team {
      max-width: 510px;
      padding: 60px 40px 80px;

      .main-title {
        font-size: 30px;
      }

      .wrapper {
        gap: 50px;
      }

      .text-wrap {
        width: 150px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .artist {
    .logo-img {
      padding: 30px 0;
      width: 80px;
    }

    .navbar {
      gap: 50px;
      padding: 0 20px;
      .text {
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .artist {
    .navbar {
      gap: 30px;
      padding: 0;

      .text {
        font-size: 18px;
      }
    }

    .content {
      padding: 40px 20px;
      margin: 50px 10px;
    }
  }
}

@media screen and (max-width: 300px) {
  .artist {
    .content {
      .data {
        .title {
          font-size: 28px;
        }
      }
    }
  }
}
